import React        from "react";
import { graphql }  from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import StyledHero from "../components/StyledHero"
import * as styles from "../css/template.module.css"
import { FaMoneyBillWave ,FaClock , FaTypo3 } from "react-icons/fa";
import Layout from "../components/Layout";
import { convertToBgImage } from "gbimage-bridge";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Seo from "../components/SEO";


const Template = ({ data }) => 
{
    const { name, timeRequired, timings, entryFees, description: { description }, images } = data.place.edges[0].node;
    console.log(images) 
    const [mainImage, ...placeImages] = images
    console.log(mainImage) 
    console.log(getImage(mainImage)) 
    const image_info = getImage(mainImage)
    const bgImage = convertToBgImage(image_info)
    console.log(placeImages)

    

    
    
    return(
        <Layout>
          <Seo title={name}/>
            <StyledHero img={bgImage.fluid} />
            <section className={styles.template}>
                <div className={styles.center}>
                    <div className={styles.images}>
                        {
                         images && images.map( (item , index)  => 
                            {
                                return <GatsbyImage image={image_info} alt="single place" className={styles.image} />
                            })
                        }
                    </div>
                    <h2> {name} </h2>
                    <div className={styles.info}>
                        <p>
                            <FaMoneyBillWave className={styles.icon} />
                            Entry Fees - {entryFees}
                        </p>
                        <p>
                          <FaClock className={styles.icon} />
                          Time Required - {timeRequired} hours
                        </p>
                         {
                           timings &&
                           <p>
                              <FaTypo3 className={styles.icon} />
                              Timings - {timings}
                           </p>
                         }
                    </div>
                   <p className={styles.desc}>
                     {description}
                   </p>
                   <AniLink fade to="/places" className="btn-primary" >
                     back to places
                   </AniLink>
                </div>
            </section>
            </Layout>
        
    )

}

export const query = graphql`
  query($slug: String!){
    place: allContentfulTourismData(filter: {slug: {eq: $slug}}) {
      edges {
        node {
            name
            slug
            timeRequired
            timings
            entryFees
            description{
                description
            }
          images {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

export default Template